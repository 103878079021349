.skFormFilters {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-label {
      label {
        font-weight: 600;
        font-size: 12px;
      }
    }

    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
      line-height: 40px;
    }
  }
}
