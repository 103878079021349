.ant-btn-primary {
  @apply bg-gradient-to-r from-blue2-700 to-blue2-500;
  transition: none;

  &:hover {
    @apply bg-gradient-to-r from-blue2-700 to-blue2-500;
  }
  &[disabled] {
    @apply bg-gradient-to-r from-blue2-700 to-blue2-500 text-white border-blue2-500;
    opacity: 0.5;
    &:hover {
      @apply bg-gradient-to-r from-blue2-700 to-blue2-500 text-white border-blue2-500;
    }
  }
}

.btn-dark {
  @apply bg-gradient-to-r from-dark-600 to-dark-400 border-dark-600;
  transition: none;

  &:hover,
  &:focus {
    @apply bg-gradient-to-r from-dark-600 to-dark-400 border-dark-600;
  }
}

.btn-gray {
  @apply bg-gradient-to-r from-gray-100 to-gray-100 border-gray-100 text-gray-700;
  transition: none;

  &:hover,
  &:focus {
    @apply bg-gradient-to-r from-gray-100 to-gray-100 border-gray-100 text-gray-800;
  }
}

.btn-gray-dark {
  @apply bg-gradient-to-r from-gray-300 to-gray-300 border-gray-300 text-gray-700;
  transition: none;

  &:hover,
  &:focus {
    @apply bg-gradient-to-r from-gray-300 to-gray-300 border-gray-300 text-gray-700;
  }
}

.btn-gray-200 {
  @apply bg-gradient-to-r from-gray-200 to-gray-200 border-gray-200 text-gray-700;
  transition: none;

  &:hover,
  &:focus {
    @apply bg-gradient-to-r from-gray-200 to-gray-200 border-gray-200 text-gray-700;
  }
}

.btn-second {
  @apply bg-gradient-to-r from-indigo-700 to-indigo-500 border-indigo-500;
  transition: none;

  &:hover,
  &:focus {
    @apply bg-gradient-to-r from-indigo-700 to-indigo-500 border-indigo-500;
  }
}

.ant-btn {
  align-items: center;
  display: inline-flex;
  justify-content: center;

  svg {
    width: 20px;
  }

  &.ant-btn-ghost {
    border-width: 2px;
    @apply border-primary text-primary;
  }
  &.ant-btn-sm {
    @apply text-xs;
    svg {
      width: 16px;
    }
  }
}
