.skAside {
  :global {
    .ant-menu-dark {
      .ant-menu-submenu-title {
        font-size: 13px;
      }
      > .ant-menu-submenu {
        border-top: 1px solid #282e4c;
        &:last-child {
          border-bottom: 1px solid #282e4c;
        }
        & > .ant-menu-submenu-title {
          text-transform: uppercase;
          font-size: 11px;
          font-weight: 600;
          display: flex;
          align-items: center;
          @media (max-width: 1024px) {
            height: 50px;
            line-height: 50px;
            font-size: 13px;
          }
        }
      }
      .ant-menu-item {
        font-size: 13px;
        @media (max-width: 1024px) {
          font-size: 14px;
        }
      }
      .ant-menu-title-content > div > a {
        height: 32px;
        line-height: 32px;
        display: block;
        color: hsla(0, 0%, 100%, 0.65);

        @media (max-width: 1024px) {
          height: 50px;
          line-height: 50px;
        }
        &:hover {
          color: #fff;
        }
      }

      .ant-menu-item-selected .ant-menu-title-content > div > a {
        color: #fff;
      }

      > .ant-menu-item {
        &:last-child {
          height: 49px !important;
          line-height: 42px !important;
          border-top: 1px solid #282e4c;
          border-bottom: 1px solid #282e4c;
          margin: 0 !important;
          text-transform: uppercase;
          font-size: 11px;
          font-weight: 600;
          display: flex;
          @media (max-width: 1024px) {
            font-size: 13px;
            height: 60px !important;
            line-height: 60px !important;
          }
        }
      }
      .ant-menu-inline.ant-menu-sub {
        background: #282e4c;
      }
      .ant-menu-item.ant-menu-item-only-child,
      .ant-menu-sub.ant-menu-inline > .ant-menu-item,
      .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
        height: 32px;
        line-height: 32px;
        @media (max-width: 1024px) {
          height: 50px;
          line-height: 50px;
        }
      }
      &.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: transparent;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.15;
          @apply bg-primary;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 3px;
          height: 100%;
          border-radius: 0 3px 3px 0;
          @apply bg-primary;
        }
      }
    }
    .ant-menu-item-group-title {
      @apply text-xs font-semibold;
      font-size: 11px;
      color: #6b74ab !important;
      @media (max-width: 1024px) {
        font-size: 13px;
      }
    }
    .scrollbar-dark {
      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        @apply bg-dark-200;
      }

      &::-webkit-scrollbar-thumb {
        @apply bg-dark-600;
        border-radius: 20px;
        border: 2px solid #6b74ab;
      }
    }
  }
}
