.ant-radio-group {
 @apply flex gap-x-2;
  .ant-radio-button-wrapper {
    @apply rounded bg-gray-100 border-0 h-[28px] leading-[28px] text-xs hover:bg-blue-50;
    &:before {
      content: none
    }
    &.ant-radio-button-wrapper-checked {
      @apply bg-blue-50;
    }
  }
  &.ant-radio-group-large {
    .ant-radio-button-wrapper {
      @apply h-[40px] leading-[40px] text-sm;
    }
  }
  &.sk-radio-custom {
    .ant-radio-wrapper {
      @apply bg-gray-100 rounded p-3 flex gap-3 flex items-center focus-within:ring focus-within:ring-blue-700 focus-within:ring-2;
      &.ant-radio-wrapper-checked {
        @apply bg-blue-50;
      }
    }
  }
}
