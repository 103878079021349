.ant-modal-wrap {
  backdrop-filter: blur(4px);
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
  }
  &.max {
    overflow: unset;
    .ant-modal-content {
      background: transparent;
    }
    .ant-modal {
      width: 90vw;
      max-width: 1500px;
      top: 50px;
      height: 90vh;
      .ant-modal-content {
        box-shadow: none;
        min-height: 90vh;
      }
    }
  }
  &.full {
    overflow: unset;
    .ant-modal-content {
      background: transparent;
      border-radius: 10px 10px 0 0;
    }
    .ant-modal {
      width: 100vw;
      top: 0;
      height: 100vh;
      max-width: 100vw;
      margin: 0 auto;
      .ant-modal-content {
        box-shadow: none;
        height: 100vh;
        border-radius: 0;
      }
    }
  }
}

@media (max-width: 1024px) {
  .ant-modal {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    max-width: 100% !important;
    top: 0 !important;
    padding: 0 !important;
    background: #fff;
    .ant-modal-content {
      border-radius: 0;
      box-shadow: none;
    }
  }
  .ant-modal-wrap {
    &.full {
      .sk-modal-body {
        height: var(--vh);
      }
    }
  }
}

