@import 'var';

html,
body {
  height: 100%;
  width: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.center-table {
  table td {
    text-align: center;
  }

  .ant-table-thead > tr > th {
    text-align: center;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  vertical-align: baseline !important;
}
