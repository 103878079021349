@import 'var';

.ant-form {
  fieldset {
    width: 100%;
  }
}

.no-padding-for-form-item {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

.ant-form-item {
  width: 100%;
}

.ant-form-item-label {
  font-weight: 500;

  > label {
    font-size: 12px;
  }
}

.ant-form-item-required {
  &:before {
    display: none !important;
  }

  &:after {
    display: inline-block !important;
    color: #f2545b !important;
    font-size: 12px !important;
    content: '*' !important;
  }
}

.ant-input-affix-wrapper.ant-input-password {
  input {
    box-shadow: none;
  }
}

.ant-select-selector {
  .ant-input {
    box-shadow: none !important;
  }
}

.ant-form-item-has-error {
  .ant-picker,
  .ant-input-number,
  .ant-input-affix-wrapper,
  .ant-select-selector,
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
  .ant-upload,
  .ant-input:not(input[type="password"]) {
    border: 2px solid rgba(255, 77, 79, 0.4);

    &:focus {
      background-color: #fff !important;
    }
  }

  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper-focused {
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(42, 94, 228, 0.4);
  }
}

.ant-form-item {
  &.hide-explain {
    .ant-form-item-explain {
      display: none;
    }
  }
}

.ant-label-and-control-inline {
  width: 100%;

  .ant-row {
    flex-direction: row;
    justify-content: start;
    align-items: center;

    .ant-form-item-control {
      flex-grow: 0;
      padding-left: 10px;
      width: auto;
    }

    .ant-form-item-label {
      padding-right: 0;
    }
  }
}

@media (max-width: 1024px) {
  .ant-input:not(.ant-input-lg) {
    height: 38px;
  }

  .ant-input-password .ant-input {
    height: 28px;
  }

  .ant-input-number-input:not(.ant-input-lg) {
    height: 38px;
  }
  .ant-input-affix-wrapper-lg {
    padding: 8px 11px;
  }
}
