.table-flex {
  display: flex;
  flex-flow: column nowrap;
  //border: 1px solid #ececec;
  flex: 1 1 auto;

  .th {
    display: flex;
    flex-flow: row nowrap;
    border-bottom: 1px solid #ececec;
    > .td {
      @apply text-gray-500;
      white-space: normal;
      justify-content: center;
      text-align: center;
      font-size: 11px;
      padding: 15px 0;
      border: 0;
      &.is-now {
        @apply text-primary;
        font-weight: bold;
      }
    }
  }

  .tr {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid #ececec;
    &:last-child {
      border-bottom: 0;
    }
  }

  .td {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    flex-basis: 0;
    word-break: break-word;
    text-overflow: ellipsis;
    min-width: 0;
    border-right: 1px solid #ececec;
    position: relative;
  }
}
