.skCalendarPickerShadowNone {
  z-index: 0 !important;
  position: relative;
  top: auto !important;
  left: auto !important;
  :global {
    .ant-picker-panel-container {
      box-shadow: none;
    }
  }
}

.skCalendarPicker {
  :global {
    .ant-picker {
      width: 31px;
      height: 32px;
      padding: 0;

      .ant-picker-input {
        input {
          display: none;
        }
      }
    }
    .ant-picker-suffix {
      color: #202542;
    }
  }
}
