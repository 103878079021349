.float-label {
  position: relative;
  top: -8px;
  margin-bottom: 10px !important;

  .ant-select-selector,
  .ant-input-number,
  input,
  textarea {
    border: 0 !important;
    padding-left: 0 !important;
    background: #fff !important;
    &::placeholder {
      color: transparent;
    }
    &:focus {
      box-shadow: none !important;
    }
  }

  .ant-select-selection-search {
    left: 0 !important;
  }
  .ant-input-number-focused,
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border: 0 !important;
    box-shadow: none !important;
  }

  .ant-input-number-handler-wrap,
  .ant-select-selection-placeholder {
    display: none;
  }
  .ant-input {
    border: 0 !important;
  }
  .ant-form-item-label {
    transition: all 0.2s ease 0s;
    position: relative;
    z-index: 1;
    top: 30px;
    padding: 0;
    > label {
      color: #516f90;
      font-size: 12px;
      font-weight: normal;
    }
  }

  &:hover,
  &.with-value {
    .ant-form-item-label {
      top: 5px;
    }
  }
  &:hover:not(.no-hover-border) {
    .ant-form-item-control-input {
      border-bottom: 1px solid #2a5ee4;
    }
    .ant-select-selection-placeholder {
      display: block;
    }
  }
}

.ant-form-item {
  &.hide-explain {
    .ant-form-item-explain {
      display: none;
    }
  }
}
