.ant-drawer-header {
  border-radius: 0 !important;
  background-image: linear-gradient(-303deg, #2d52c4, #3979ed 56%, #3b9df1) !important;
  border: 0 !important;
  padding: 0 !important;
}
.ant-drawer-title {
  font-size: 20px !important;
  color: #fff !important;
  font-weight: 400 !important;
}
.ant-drawer-close {
  padding: 15px !important;
  color: #fff !important;
  z-index: 1000;
  &:hover,
  &:focus {
    color: #fff !important;
    outline: 0;
  }
}
.ant-drawer-body {
  padding: 0 !important;
}
.ant-drawer-footer {
  padding: 0 !important;
}

@media (max-width: 1024px) {
  .ant-drawer-header {
    background: #202542 !important;
  }
}

.skDrawerMenu {
  width: 100vw;
  height: 100vh;
}

