.ant-tabs {
  overflow: visible !important;
  &.tabs-with-tab-link {
    .ant-tabs-tab {
      padding: 0;
    }
  }
}
.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0 !important;
}
