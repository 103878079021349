@import 'var';

.ant-table {
  .ant-form-item {
    padding-top: 0 !important;
  }
  &.ant-table-empty {
    .ant-table-cell {
      border-bottom: 0;
    }
  }
}
.ant-table-column-sorters {
  @apply justify-start;
  .ant-table-column-sorter {
    @apply pl-2;
  }
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table-column-title {
  display: contents;
}

.ant-table-thead > tr > th {
  font-size: 12px !important;
  text-transform: uppercase !important;
  color: $text-color !important;
  font-weight: 600 !important;
  border-bottom: 1px solid #f0f0f0 !important;
  &.ant-table-column-has-sorters {
    border-bottom: 1px solid #f0f0f0 !important;
  }
}

.ant-table-tbody > tr > td {
  border-bottom-width: 1px;
}

.ant-table-wrapper {
  &.bg-transparent {
    .ant-table,
    .ant-table-thead > tr > th {
      background: transparent;
    }
  }
}
