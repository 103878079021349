@import 'var';

.ant-empty-normal {
  color: #b1b8bf !important;
}

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: 0 !important;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0 !important;
}
.ant-tooltip-inner {
  font-size: 13px;
}
.ant-select-selection-item {
  display: flex;
  justify-content: flex-start !important;
}
.ant-image {
  //display: contents !important;
}

.min-w-screen.min-h-screen {
  .ant-spin-nested-loading {
    height: 100vh;
  }
}

.ant-dropdown-menu-submenu-title {
  @apply flex items-center;
}
