@import '../../../styles/var';

.skAsideBody {
}

.skSearch {
  background: $dark-color-400 !important;
  width: 100%;
  height: 34px;
  box-shadow: none !important;
  border: 0;

  input {
    background: $dark-color-400 !important;
    font-size: 12px !important;
    color: #fff !important;
    box-shadow: none;

    &::placeholder {
      @apply text-gray-500;
    }

    &:focus,
    &:active {
      color: $text-color !important;
      background: #fff !important;
    }
  }

  :global {
    .ant-input-prefix {
      @apply text-gray-500 mr-2;
    }
  }
}

.skSearch.ant-input-affix-wrapper-focused {
  background: #fff !important;
}
