.ant-select {
  &.h-10 {
    .ant-select-selector {
      height: 42px !important;
      .ant-select-selection-placeholder,
      .ant-select-selection-search-input {
        line-height: 40px !important;
        height: 40px !important;
      }
    }
  }
}
.ant-select-dropdown {
  &.ant-select-item-option-with-border-b {
    .ant-select-item-option {
      @apply border-b border-gray-100 py-3;
    }
  }
}
@media (max-width: 1024px) {
  .ant-select {
    .ant-select-selector {
      height: 38px !important;

      .ant-select-selection-placeholder,
      .ant-select-selection-search-input {
        line-height: 36px !important;
        height: 36px !important;
      }
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 36px !important
  }
}
