.ant-dropdown-menu {
  padding: 5px !important;
}

.ant-dropdown-menu-item {
  @apply rounded-lg;
  align-items: center;
  display: flex !important;

  > svg {
    margin-right: 10px;
    width: 20px;
  }

  &:hover {
    @apply text-blue2-600;
  }
}

.ant-dropdown-menu-title-content {
  @apply flex;
}

@media (max-width: 1024px) {
  .ant-select-item {
    padding: 12px 12px;
  }
}

@media (max-width: 1024px) {
  //.ant-dropdown {
  //  left: 0 !important;
  //  width: 100vw;
  //}
}
